.home {

}


html {
  --main-color: $brand-primary;
}

.content {
  .btn-primary {
    background: var(--main-color, $brand-primary);
    outline: 0;
    border-color: var(--main-color, $brand-primary);
  }

  .btn-primary:hover {
    filter: brightness(85%);
  }

  /*a {
    color: var(--main-color, $brand-primary);
  }

  a:hover {
    filter: brightness(85%);
  }*/

  p {
    a {
      color: var(--main-color, $brand-primary);
    }

    a:hover {
      filter: brightness(85%);
    }
  }
}

.columns-rows section .column .icon, .download-table section .column .version {
  color: var(--main-color, $brand-primary);
}

.video-action {
  background-color: var(--main-color, $brand-primary);
}

.related-blogposts {
  background: var(--main-color, $brand-primary);

  h4 {
    color: var(--main-color, $brand-primary);

    a {
        color: var(--main-color, $brand-primary);
    }

    a:hover {
      filter: brightness(85%);
    }
  }

  .date {
    a {
        color: var(--main-color, $brand-primary);
    }
  }
}

.bg-primary {
    background-color: var(--main-color, $brand-primary) !important;
}

.columns-rows {
  .icon {
    color: var(--main-color, $brand-primary);
  }
}

.product-listing {
	.card-img-top {
		height: auto !important;
		width: 100% !important;
	}
}